import * as React from 'react'
import { Link } from 'gatsby'

const Sidebar = () => {

  return (
    <div className="bg-gray-100 p-6 border-t-8 rounded-br-3xl border-persian-green-500">
      <ul className="space-y-2">
        <li className="hover:translate-x-2 transition-transform"><Link to="/caratteristiche-per-aprire-un-call-center" className="hover:text-persian-green-600" activeClassName="text-persian-green-500">Panoramica</Link></li>
        <li className="hover:translate-x-2 transition-transform"><Link to="/caratteristiche/campagne-con-tecnologia-progressive-mode" className="hover:text-persian-green-600" activeClassName="text-persian-green-500">Campagne con tecnologia Progressive Mode</Link></li>
        <li className="hover:translate-x-2 transition-transform"><Link to="/caratteristiche/campagne-con-tecnologia-easypredictive" className="hover:text-persian-green-600" activeClassName="text-persian-green-500">Campagne con tecnologia EasyPredictive</Link></li>
        <li className="hover:translate-x-2 transition-transform"><Link to="/caratteristiche/campagne-manuali-da-liste-cartacee" className="hover:text-persian-green-600" activeClassName="text-persian-green-500">Campagne manuali da liste cartacee</Link></li>
        <li className="hover:translate-x-2 transition-transform"><Link to="/caratteristiche/monitoraggio-dettagliato-del-call-center" className="hover:text-persian-green-600" activeClassName="text-persian-green-500">Monitoraggio dettagliato del call center</Link></li>
        <li className="hover:translate-x-2 transition-transform"><Link to="/caratteristiche/agenda-appuntamenti" className="hover:text-persian-green-600" activeClassName="text-persian-green-500">Telemarketing con presa appuntamenti</Link></li>
        <li className="hover:translate-x-2 transition-transform"><Link to="/caratteristiche/approvvigionamento-clienti-per-eventi-meeting-di-formazione-meeting-vendite" className="hover:text-persian-green-600" activeClassName="text-persian-green-500">Gestione eventi e meeting</Link></li>
        <li className="hover:translate-x-2 transition-transform"><Link to="/caratteristiche/web-api-integrazione-con-software-call-center" className="hover:text-persian-green-600" activeClassName="text-persian-green-500">Web Api – integrazione con software esterni</Link></li>
        <li className="hover:translate-x-2 transition-transform"><Link to="/caratteristiche/telemarketing_da_casa" className="hover:text-persian-green-600" activeClassName="text-persian-green-500">Telemarketing da casa senza sforzi</Link></li>
        <li className="hover:translate-x-2 transition-transform"><Link to="/caratteristiche/teleselling" className="hover:text-persian-green-600" activeClassName="text-persian-green-500">Teleselling gli strumenti giusti per farlo correttamente</Link></li>
        <li className="hover:translate-x-2 transition-transform"><Link to="/caratteristiche/centralino-telefonico-cloud-evoluto-con-tecnologia-voispeed" className="hover:text-persian-green-600" activeClassName="text-persian-green-500">Centralino telefonico cloud evoluto con tecnologia Voispeed</Link></li>
      </ul>
    </div>
  )
}

export default Sidebar