import * as React from 'react'
import { PhoneIcon, MicrophoneIcon, PaperAirplaneIcon, CalendarIcon, PlayIcon, UsersIcon, UserIcon, UserGroupIcon, ChatAltIcon, DeviceMobileIcon, ChatIcon, OfficeBuildingIcon, DocumentRemoveIcon, DocumentReportIcon, SunIcon, IdentificationIcon } from '@heroicons/react/outline'

const VoipFeature = () => {
    return (
        <div className="p-4 grid grid-cols-2 lg:grid-cols-3 gap-8">

            <div className="flex feature-panel gap-3">
                <div>
                    <PhoneIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>Funzionalità telefoniche</h3>
                    <p>La UI di VOIspeed UCloud rende più rapide e intuitive le attività telefoniche e offre funzionalità di Unified Communication avanzate.</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <MicrophoneIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>Registrazione chiamate</h3>
                    <p>Registra le chiamate in corso, riascolta, cancella e scarica le registrazioni.</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <PaperAirplaneIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>Note di chiamata</h3>
                    <p>Con le note di chiamata puoi associare una nota descrittiva ad ogni attività telefonica. Troverai le tue note nei rapporti.</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <CalendarIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>Pianificazione attività</h3>
                    <p>Pianifica le tue telefonate e gestisci le attività scadute in modo semplice e intuitivo.</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <PlayIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>Risponditore automatico (IVR)</h3>
                    <p>Con VOIspeed hai a disposizione un risponditore automatico multilivello (IVR) per offrire ai tuoi clienti un'immagine professionale fin dal primo contatto!</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <UsersIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>Dettaglio contatti</h3>
                    <p>La vista dettaglio contatti ti permette di visualizzare in un’unica interfaccia, tutte le attività telefoniche intercorse con un contatto/interno con le relative note di chiamata.</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <UserIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>Visualizzazione stato utenti</h3>
                    <p>Visualizza lo stato degli utenti per sapere se sono già impegnati in altre conversazioni.</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <UserGroupIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>Gruppi di squillo</h3>
                    <p>Ottimizza la gestione delle chiamate in entrata: con i gruppi di squillo puoi creare insieme di utenti e governare il flusso delle chiamate in coda.</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <ChatAltIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>SMS</h3>
                    <p>Semplifica la comunicazione con i tuoi clienti: con VOIspeed potrai inviare SMS singoli o massivi con un click!</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <DeviceMobileIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>APP</h3>
                    <p>Con l'APP iOS e Android dedicata potrai trasformare il tuo smartphone in un interno del centralino e sfruttarne tutte le potenzialità anche in mobilità</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <ChatIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>Chat</h3>
                    <p>Con la chat integrata potrai semplificare lo scambio di informazioni e/o files all'interno del tuo studio o della tua azienda.</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <OfficeBuildingIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>Unificazione delle sedi remote</h3>
                    <p>Hai sedi remote? VOIspeed ti permette di unificare la tua soluzione di telefonia e di gestirle come se facessero parte di un unico centralino.</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <DocumentReportIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>Reportistica chiamate</h3>
                    <p>Visualizza i report e rendiconta le attività telefoniche svolte.</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <SunIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>Chiusure aziendali</h3>
                    <p>Pianifica gli orari del tuo studio o della tua azienda e imposta il risponditore automatico in base alle tue esigenze in modo semplice e veloce.</p>
                </div>
            </div>

            <div className="flex feature-panel gap-3">
                <div>
                    <IdentificationIcon className="w-6 h-6" />
                </div>
                <div>
                    <h3>Rubrica centralizzata</h3>
                    <p>Condividi la rubrica aziendale con tutti gli interni del centralino e semplifica la condivisione delle informazioni.</p>
                </div>
            </div>

            <div>
                <a className="button-lg bg-persian-green-500 hover:bg-persian-green-600 transition-colors text-white inline-flex" href="http://www.voispeed.com/" target="_blank" rel="noopener noreferrer" title="Sito Voispeed">Visita il sito del nostro partner VOIspeed</a>
            </div>

        </div>
    )
}

export default VoipFeature